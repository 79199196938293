.nav-link {
  text-decoration: none;
  color: white;
  background: none;
  padding: $s-size $s-size;
  margin-right: $s-size;
  text-align: center;
  outline: none;
  border: none;

  @media (max-width: $desktop-breakpoint) {
    margin-right: $xs-size;
    padding: $xs-size;
    font-size: $font-size-small;
  }
}

.nav-link:hover {
  color: #ccc;
}
