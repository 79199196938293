.list-body {
  margin-bottom: $m-size;
  display: flex;
  flex-flow: row wrap;
  padding: $s-size;
}

.list__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $xl-size 0;
  @media (max-width: desktop-breakpoint) {
    padding: $m-size 0;
  }
}

.list__title {
  color: #05445e;
  margin: 0 $s-size $m-size $s-size;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  background: none;
  padding: $s-size $l-size;
  border-bottom: 6px solid #50655b;
  font-size: $l-size;
  font-weight: 800;
  @media (max-width: $desktop-breakpoint) {
    font-size: $m-size;
    padding: $s-size;
    margin: $s-size $s-size;
  }
}

.list-item {
  position: relative;
  border: 1px solid grey;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //flex: 30rem;
  margin: $xs-size;
  line-height: 3rem;
  padding: $s-size;
  transition: transform 200ms ease-in-out;
  @media (max-width: $desktop-breakpoint) {
    margin: 0 $s-size $s-size $s-size;
  }
}

.list-item:hover {
  transform: scale(1.02);
}

.list-item__title {
  text-decoration: none;
  color: #05445e;
  outline: none;
  margin-bottom: $s-size;
  font-weight: bolder;
}

.list-item__title:hover {
  color: darken(#05445e, 7%);
}

.list-item__date {
  color: #50655b;
  font-size: 1.3rem;
}

.list-item__desc {
  text-align: justify;
  margin-bottom: $xl-size;
}

.list-item__site {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 1.25px;
  @media (max-width: $desktop-breakpoint) {
    margin: 0 auto;
  }
}

.site-link {
  text-decoration: none;
  color: white;
  background: #1c9b8e;
  padding: $xs-size $xs-size;
  line-height: 1.6;
  width: 10rem;
  text-align: center;
  font-weight: bolder;
  border-radius: 20px;
  margin-right: 10px;
}

.site-link:hover {
  background: darken(#1c9b8e, 10%);
  color: white;
}
