.footer {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer--noscroll {
  background: #189ab4;
}

.footer--scroll {
  background: #1c9b8e;
}

.footer-content {
  color: #fff;
  font-size: $font-size-medium;
  font-weight: 500;
  @media (max-width: $desktop-breakpoint) {
    font-size: $font-size-small;
  }
}

.footer__socials {
  a {
    margin-right: 1rem;
  }
}
