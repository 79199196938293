.box-layout {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.box-layout__box {
   border: 1px solid #ccc;
   border-radius: 5px;
   width: 50rem;
   @media (max-width: $desktop-breakpoint) {
    margin:0 $s-size;
  }
}
.box-layout__body {
    padding: 0 $m-size $m-size $m-size;
}

.box-layout__title {
    margin: 0 0 $m-size 0;
    background: $blue;
    text-align: center;
    color: white;
    padding: $m-size 0;
}