* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

html {
  font-size: 62.5%; //( 1 rem = 10px)
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
  font-family: sans-serif, Helvetica, Arial;
  font-size: $font-size-medium;
  line-height: 1.6;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.1;
}
