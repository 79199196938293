.form {
    display: flex;
    padding: $s-size;
    flex-direction: column;
    > * {
        margin-bottom: $l-size;
    }

    @media(max-width: $desktop-breakpoint) {
       padding: 0 $m-size;
       > * {
           margin-bottom: $m-size;
       }
    }
}