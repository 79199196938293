.text-input {
    border: 1px solid #cacccd;
    height: 50px;
    padding: $s-size;
    font-size: $font-size-large;
    font-weight: 300;
    border-radius: 5px;
}

.text-input:focus {
    background: $off-white;
    outline: none;
}

.textarea {
    @extend .text-input;
    height: 30rem;
}