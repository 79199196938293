//colors
$dark-grey: #333333;
$off-grey: #ccc;
$grey: #666;
$blue: #1c88bf;
$dark-blue: #364051;
$off-white: #f7f7f7;
$green: darkolivegreen;

//font sizes
$font-size-large: 2.5rem;
$font-size-xlarge: 5rem;
$font-size-small: 1.4rem;
$font-size-xsmall: 1.2rem;
$font-size-medium: 1.6rem;

//spacing
$xs-size: 0.5rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$xxl-size: 12.5rem;

//responsive
$desktop-breakpoint: 45rem;
