.aboutme {
  background: #50655b;
  height: 92vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $m-size;
}

.aboutme-content {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $desktop-breakpoint) {
    flex-direction: column;
    padding: $l-size;
  }
}

.aboutme__info {
  @media (max-width: $desktop-breakpoint) {
    text-align: center;
  }
}

.aboutme__title {
  font-size: calc(1.3rem + 3.6vw);
  strong {
    font-weight: 800;
    color: #1c9b8e;
  }
}

.aboutme__subtitle {
  color: darken(#fff, 0.5);
  font-size: calc(1rem + 1.1vw);
}

.aboutme-img {
  width: 200px;
  height: 200px;
  margin-right: $m-size;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  @media (max-width: $desktop-breakpoint) {
    border-radius: 10px;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin-bottom: $m-size;
  }
}

.view-info {
  text-decoration: none;
  font-size: calc(0.9rem + 1vw);
  color: white;
  background: #05445e;
  display: block;
  text-align: center;
  font-weight: 800;
  width: 100%;
  padding: $m-size;
  border-radius: 8px;
  margin-top: $l-size;
  transition: all 300ms ease-in;
  @media (max-width: $desktop-breakpoint) {
    padding: $s-size;
    width: 100%;
  }
}

.arrow {
  border: solid white;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 3px;
  transition: all 500ms ease-in-out;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin: 0 $s-size;
}

.view-info:hover {
  background: lighten(#05445e, 7%);
  .arrow {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
