.header {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
}

.header--noscroll {
  background: #189ab4;
  transition: all 0.4s ease-in-out;
}

.header--scroll {
  background: #1c9b8e;
  transition: all 0.4s ease-in;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $s-size $s-size;
}

.header__title {
  color: whitesmoke;
  text-decoration: none;
  font-weight: bolder;
  outline: none;
  font-size: calc(1rem + 0.2vw);

  @media (max-width: $desktop-breakpoint) {
    font-size: $s-size;
  }
}

.header__subtitle {
  background: darken(#05445e, 7%);
  color: white;
  margin: $l-size $s-size;
  display: inline-block;
  padding: $m-size;
  border-radius: 10px;
  font-size: $m-size;
  @media (max-width: $desktop-breakpoint) {
    margin: $s-size;
    font-size: $m-size;
    padding: $s-size;
  }
}

.header__subtitle:hover {
  background: lighten(#05445e, 2%);
}
